import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const TextArea = (props) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (props.defaultValue) {
      setCounter(props.defaultValue.length);
    }
  }, [props.defaultValue]);

  const onTextAreaChange = (e) => {
    props.onChange(e);
    setCounter(e.target.value.length);
  };

  return (
    <>
      <textarea
        id={props.id}
        className={`textarea ${props.className}`}
        placeholder={
          props.intl.messages[props.placeholder] || props.placeholder
        }
        onChange={onTextAreaChange}
        defaultValue={
          props.intl.messages[props.defaultValue] || props.defaultValue
        }
        maxLength={props.limit}
        disabled={props.disabled}
      />
      {props.limit && (
        <div className='text-paragraph --small text-align-right limit-counter italic'>
          ({counter}/{props.limit})
        </div>
      )}
    </>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(TextArea);

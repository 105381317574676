import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Alert from '../../molecules/alerts/Alert';
import { ReactComponent as SittingGuy } from '../../../assets/imgs/sitting-guy.svg';
import { ReactComponent as CurvedArrow } from '../../../assets/imgs/curved-arrow.svg';
import { hasFavorites } from '../../templates/helper/documentsTabHelper';

const MessageKeys = {
  Sequences: 'noResultsToSequences',
  Contacts: 'noResultsToContacts',
};

const NoSearchResults = (props) => {
  return (
    <div className='no-search-results'>
      <div className='no-results-content'>
        <div className='text-header h2'>
          <FormattedMessage
            id={MessageKeys[props.documentsTab] || 'noResults'}
          />
          <CurvedArrow />
        </div>
        <Paragraph className='text-paragraph'>
          <FormattedMessage id={'trySearchAgain'} />
        </Paragraph>
        {hasFavorites(props.documentsTab) && (
          <Alert
            alertType='alert-information text-align-left'
            headerMessage={
              <FormattedMessage
                id={'searchInfo'}
                values={{
                  span: (chunks) => (
                    <span className='font-regular'>{chunks}</span>
                  ),
                }}
              />
            }
            headerIconClassName='fas fa-info-circle'
          />
        )}
      </div>
      <SittingGuy />
    </div>
  );
};

export default injectIntl(NoSearchResults);

const DECIMAL_PLACES = 2;
const DELIMITERS = {
  dot: ',',
  comma: '.',
};

/**
 * Replace value delimiters in string
 * @function
 * @param {string} value - monetary value.
 * @returns {string} monetary value with correct delimeters.
 */
const changeDelimiters = (value) => {
  return value.replace(/.|,/gi, function (char) {
    if (char === '.') {
      return DELIMITERS.dot;
    } else if (char === ',') {
      return DELIMITERS.comma;
    } else {
      return char;
    }
  });
};

/**
 * Format a monetary value
 * Why de-DE? The locale that puts the currency on the right.
 * Read more: https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 * @function
 * @param {string} value - monetary value.
 * @param {string} currency - currency code.
 * @param {string} separator - separator for decimal values.
 * @returns {string} formatted monetary value.
 */
export const formatMoney = (value, currency, separator) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency,
    useGrouping: true,
    minimumFractionDigits: DECIMAL_PLACES,
  });

  value = formatter.format(value).replace(/\s/g, '');

  if (separator !== ',') {
    value = changeDelimiters(value);
  }
  return value;
};

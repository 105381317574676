import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object (className, children, intl, onClick function).
 */

const Label = props => {

  return(
  <label id={props.id} className={props.className} onClick={props.onClick}>
    {props.intl.messages[props.children] || props.children}
  </label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  onClick: PropTypes.func,
  id: PropTypes.string
};
export default injectIntl(Label);

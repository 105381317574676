import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { ReactComponent as SittingGuy } from '../../../../assets/imgs/sitting-guy.svg';

const NotFoundCard = (props) => {
  return (
    <div className='no-search-results --card-not-found'>
      <div className='no-results-content'>
        <div className='text-header h2'>
          <FormattedMessage id={props.title} />
        </div>
        <Paragraph className='text-paragraph '>{props.children}</Paragraph>
      </div>
      <SittingGuy height={200} width={150} />
    </div>
  );
};

export default injectIntl(NotFoundCard);

import React from 'react';
import PropTypes from 'prop-types';
import Span from '../../atoms/spans/Span';
import Input from '../../atoms/inputs/Input';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const CheckBoxLabel = (props) => {
  const { id, className, checked, onChange, value, disabled } = props;

  return (
    <label className={className}>
      <Input
        id={id}
        type='checkbox'
        name='...'
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {props.intl.messages[props.children] || props.children}
      <Span className='checkmark' />
    </label>
  );
};

CheckBoxLabel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default injectIntl(CheckBoxLabel);

import RequestAdapter from '../adapters/RequestAdapter';
import { V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

export const NOTIFICATIONS_TYPE = {
  EMAIL: 'open_account_link',
};
Object.freeze(NOTIFICATIONS_TYPE);

/**
 * Get template configuration
 *
 * @param {string} type
 * @returns
 */
const getTemplateByType = async (type) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.NOTIFICATIONS.TEMPLATES(type)}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Get billing alerts configuration
 *
 * @param {object} formData - client id
 * @returns
 */
const getBillingAlertConfiguration = async (clientId) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.NOTIFICATIONS.BILLING_ALERTS}?client_id=${clientId}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Create billing alerts configuration
 *
 * @param {object} formData
 * @returns
 */
const createBillingAlertConfiguration = async ({ client_id, ...formData }) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.NOTIFICATIONS.BILLING_ALERTS}`,
    method: 'POST',
    body: JSON.stringify({
      client_id,
      billing_alert: formData,
    }),
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Update billing alerts configuration
 *
 * @param {object} formData
 * @returns
 */
const updateBillingAlertConfiguration = async ({
  client_id,
  configurable,
  ...formData
}) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.NOTIFICATIONS.BILLING_ALERTS}`,
    method: 'PUT',
    body: JSON.stringify({
      client_id,
      billing_alert: formData,
    }),
  };

  return RequestAdapter.request(requestConfig);
};

export const NotificationService = {
  getTemplateByType,
  getBillingAlertConfiguration,
  createBillingAlertConfiguration,
  updateBillingAlertConfiguration,
};

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Image = (props) => {
  return <img src={props.src} alt={props.alt} className='img' />;
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;
import React from 'react';
import { Route } from 'react-router-dom';
import CustomizeDocumentLayout from '../../../components/templates/customize-document/CustomizeDocumentLayout';
import DigitalSignatureLayout from '../../../components/templates/settings/digital-signature/DigitalSignatureLayout';
import { CUSTOMIZE_DOCUMENT, SETTINGS } from '../../../constants/url';

/**
 * Creates the routes necessary to render the account settings
 *
 * @returns {Array} routes created on the AccountSettings submenu
 */
export const AccountSettingsRoutes = () => [
  <Route exact path={[CUSTOMIZE_DOCUMENT]} key={CUSTOMIZE_DOCUMENT}>
    <CustomizeDocumentLayout documentsTab={'CustomizeDocument'} />
  </Route>,

  <Route path={[SETTINGS.DIGITAL_SIGNATURE]} key={SETTINGS.DIGITAL_SIGNATURE}>
    <DigitalSignatureLayout documentsTab={'DigitalSignature'} />
  </Route>,
];

import React from 'react';
import { injectIntl } from 'react-intl';
import Anchor from '../../atoms/anchors/Anchor';
import Span from '../../atoms/spans/Span';
import { buildPath } from '../../organisms/containers/util/pathHelper';

/**
 * ES6 stateless component
 */
const FeatureNotAvailableBanner = (props) => (
  <div className='banner-container --not-avaliable'>
    <Span>{props.intl.messages['featureNotAvailable']}</Span>
    <Anchor
      href={buildPath('accounts/manage_plan')}
      className='button button-secondary'
    >
      {props.intl.messages['upgradeButton']}
    </Anchor>
  </div>
);

export default injectIntl(FeatureNotAvailableBanner);

import {
  FETCH_DOCUMENTS_PATHS,
  SEQUENCES_ACTIONS_PATH,
  REQUEST_DELAY,
  V3_PATH_BASE,
  SEQUENCES_PATH,
  SEQUENCES_COLLECTIONS_PATH,
} from '../../../../../constants';
import { DEFAULT_SEQUENCES_RESPONSE } from '../../../../../constants/defaultValues';
import {
  fetchData,
  fetchDataRaw,
  fetchDocuments,
} from '../../../invoices/util/api/request';
import { buildDefaultSequenceOptions } from './requestHelper';
import {
  buildCreateSequenceBody,
  logHTTPError,
} from '../../../invoices/util/api/requestHelper';
import { lowerCaseFirstLetter } from '../../../../../formatters/string';
import { buildQueryStringParam } from '../queryStringHelper';

/**
 * Requests the BE for the sequences data.
 *
 * @function
 *
 * @param {object} accountInformation - JSON object with the information from the user's account
 * as the language, account_id, etc.
 * @param {object} searchInformation - JSON object with the search information performed by the user
 * as the document tab, the applied filters and the windowLocation object.
 *
 * @returns {object} JSON API response
 */
export async function fetchSequencesData(
  accountInformation,
  searchInformation
) {
  const sequences = await fetchDocuments(
    accountInformation.accountId,
    accountInformation.language,
    searchInformation.documentsTab,
    searchInformation.filters,
    searchInformation.windowLocation
  );

  if (sequences.hasOwnProperty('error')) {
    return DEFAULT_SEQUENCES_RESPONSE;
  } else {
    return sequences;
  }
}

/**
 * Requests the BE to set the current serie as default.
 *
 * @function
 *
 * @param {number} accountId - ID of the user's account
 * @param {string} serieName - sequence name that should be set as default
 *
 * @returns {object} JSON response
 */
export async function setDefaultSequence(accountId, seriesName) {
  const requestOptions = buildDefaultSequenceOptions(accountId, seriesName);

  return await fetchData(
    `${SEQUENCES_ACTIONS_PATH['setDefault']}`,
    'sequences',
    requestOptions
  );
}

/**
 * Checks the status of the create process
 *
 * @function
 *
 * @param {string} statusEndpoint - endpoint that should be used to request the status of the Create operation
 * @param {Function} handleResponseCallback - function that handles the update of the React State and disables the loading.
 * @param {number} timerId - an Integer that represents the identifier of the timer.
 */
const checkCreateStatus = async (
  statusEndpoint,
  handleResponseCallback,
  timerId
) => {
  const path = `${V3_PATH_BASE}${SEQUENCES_PATH}${statusEndpoint}`;

  try {
    const statusResponse = await fetch(path);
    const responseJson = await statusResponse.json();

    if (statusResponse.status !== 202) {
      handleResponseCallback(responseJson);
      clearInterval(timerId);
    }
  } catch {
    const errorResponse = {
      errors: [
        {
          code: 'globalError',
        },
      ],
    };

    handleResponseCallback(errorResponse);
    clearInterval(timerId);
  }
};

/**
 * Requests the BE to create a sequence.
 *
 * @function
 *
 * @param {String} serieName - Name of the serie.
 * @param {Boolean} setAsDefault - Boolean that is true when the sequence is to be
 * set as default for the account.
 * @param {Integer} accountId - Account identifier.
 * @param {String} documentsTab - Current tab name.
 * @param {boolean} createAndRegistry - Indicate if the serie is to be registered or not.
 *
 * @returns {object} JSON API response
 */
export async function createSequence(
  sequenceName,
  setAsDefault,
  accountId,
  documentsTab,
  createAndRegistry,
  handleResponseCallback
) {
  const path = `${FETCH_DOCUMENTS_PATHS[lowerCaseFirstLetter(documentsTab)]}`;
  const dataType = 'sequences';
  const options = {
    method: 'POST',
    body: buildCreateSequenceBody(
      accountId,
      sequenceName,
      setAsDefault,
      createAndRegistry
    ),
  };

  const response = await fetch(path, options);
  const responseJson = await response.json();

  if (response.status === 500) {
    logHTTPError(dataType, response);
    handleResponseCallback({ error: true });
    return;
  }

  if (response.status === 400) {
    handleResponseCallback(responseJson);
    return;
  }

  let timerId = setInterval(async () => {
    await checkCreateStatus(
      responseJson.status_endpoint,
      handleResponseCallback,
      timerId
    );
  }, REQUEST_DELAY);
}

/**
 * Requests the BE for information about a specific sequences collection.
 *
 * @function
 *
 * @param {number} accountId - ID of the user's account.
 * @param {string} collectionName - Name of the sequences collection.
 *
 * @returns {object} JSON response
 */
export async function fetchSequencesCollection(accountId, collectionName) {
  const queryString = buildQueryStringParam('account_id', accountId);

  const path = `${V3_PATH_BASE}${SEQUENCES_COLLECTIONS_PATH}/${collectionName}?${queryString}`;
  return await fetchData(path, 'sequences collections');
}

/**
 *
 * Requests the BE to register a specific sequences collection.
 *
 * @function
 *
 * @param {number} accountId - ID of the user's account.
 * @param {string} collectionName - Name of the sequences collection.
 *
 * @returns {object} JSON response
 */
export async function registerSequencesCollection(
  accountId,
  collectionName,
  handleRegisterResponse
) {
  const requestOptions = buildDefaultSequenceOptions(accountId, collectionName);

  const registerResponse = await fetchDataRaw(
    `${SEQUENCES_ACTIONS_PATH['register']}`,
    'sequences',
    requestOptions
  );

  if (registerResponse.data.errors) {
    handleRegisterResponse(registerResponse.data);
    return;
  }

  let timerId = setInterval(async () => {
    await checkCreateStatus(
      registerResponse.data.status_endpoint,
      handleRegisterResponse,
      timerId
    );
  }, REQUEST_DELAY);
}

import React from 'react';
import PropTypes from 'prop-types';
import Span from '../spans/Span';
import Input from '../inputs/Input';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Toggle = (props) => {
  const {
    id,
    className,
    defaultChecked,
    checked,
    onChange,
  } = props;
  return (
    <label className={className}>
      <Input
        id={id}
        type='checkbox'
        name='...'
        value='value'
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <Span className='slider' />
    </label>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectIntl(Toggle);

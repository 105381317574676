import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ListingRoutes } from './routes/ListingRoutes';
import { INVOICES_URL } from '../constants/url';
import AppContextProvider from '../contexts/AppContext';

import * as helpScoutBeaconHelper from '../helpers/helpScoutBeaconHelper';
import { ClientRoutes } from './routes/client/ClientRoutes';
import { IXLabRoutes } from './routes/ix-labs/IXLabRoutes';
import { AccountSettingsRoutes } from './routes/account-settings/AccountSettingsRoutes';
import { PublicRoutes } from './routes/public';

const browserIsOldEdge =
  navigator.userAgent.toUpperCase().indexOf('EDGE') !== -1;

export const LayoutBuilder = ({ layoutProps, language, messages }) => (
  <IntlProvider locale={language} messages={messages}>
    <AppContextProvider stateProps={layoutProps}>
      <BrowserRouter>
        <Switch>
          {/* Listing pages: Invoices, Estimates and Guides */}
          {ListingRoutes(layoutProps)}

          {/* IX Lab menus */}
          {IXLabRoutes(layoutProps)}

          {/* Clients */}
          {ClientRoutes(layoutProps)}

          {/* Account Settings */}
          {AccountSettingsRoutes(layoutProps)}

          {PublicRoutes(layoutProps)}

          {/* Default route */}
          <Route>
            <Redirect to={INVOICES_URL} />
          </Route>
        </Switch>
      </BrowserRouter>
    </AppContextProvider>
    {helpScoutBeaconHelper.showOldEdgeWarningMessage(
      language,
      browserIsOldEdge
    )}
  </IntlProvider>
);

export default LayoutBuilder;

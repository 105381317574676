import React, { Component } from 'react';
import MobileMenu from '../../organisms/containers/mobile/MobileMenu';
import RightMenu from '../../organisms/containers/sequences/RightMenu';
import { parseAndSetSearchFilters } from '../invoices/util/filters/filtersManager';
import SearchBox from '../../organisms/containers/sequences/SearchBox';
import { FormattedMessage } from 'react-intl';
import TableSimple from '../../organisms/tables/TableSimple';
import { documentsPerPage } from '../util/listing/items-per-page/itemsPerPageManager';
import { fetchStatusDocuments } from '../util/listing/filters/status/statusManager';
import { fetchTypeDocuments } from '../util/listing/filters/type/typeManager';
import { sortDocuments } from '../util/listing/sort/sortDocumentsManager';
import { sequencesSortOptions } from '../../templates/invoices/util/filters/defaultFilters';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import * as queryStringManager from '../util/api/queryStringManager';
import * as request from '../invoices/util/api/request';
import * as sequencesRequest from '../util/api/sequences/request';
import * as templateInformation from '../helper/templateInformationHelper';
import * as stateManager from '../state-manager/stateManager';
import { Request as AccountsRequest } from '../util/api/accounts/Request';
import { AT_CONTEXT_SEQUENCES, AT_TYPE } from '../../../constants/at';

/**
 * Main component (entry point) for Sequences listing
 * @class
 * Renders all the main elements
 * Sets the main app state
 * Holds the main functions to change the state and perform external requests
 */
export default class SequencesLayout extends Component {
  _isMounted = false;

  /**
   * @constructor
   * Sets all the app state
   * @param {object} props - React props object (account_id, user_id, location object)
   */
  constructor(props) {
    super(props);

    this.state = {
      globalResetKey: 1,
      globalTableKey: 1,
      accountId: props.accountId,
      userId: props.userId,
      userRole: props.userRole,
      language: props.language,
      windowLocation: props.windowLocation,
      isLoading: true,
      isLoadingFirstRequest: true,
      sequences: [],
      numberOfPages: null,
      filters: parseAndSetSearchFilters(props),
      totals: {},
      showMobileMenu: false,
      mobileSideContent: '',
      favoriteUID: '',
    };

    this.initialState = JSON.parse(JSON.stringify(this.state));
    this.setDocumentsAndTotals = this.setDocumentsAndTotals.bind(this);
    this.getSequencesByTextInput = this.getSequencesByTextInput.bind(this);
    this.setItemsPerPage = this.setItemsPerPage.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.sortDocumentsByPropertyAndOrder =
      this.sortDocumentsByPropertyAndOrder.bind(this);
    this.getDocumentsPage = this.getDocumentsPage.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.getDocumentsByType = this.getDocumentsByType.bind(this);
    this.getDocumentsByStatus = this.getDocumentsByStatus.bind(this);
    this.getAccountActiveCommunication =
      this.getAccountActiveCommunication.bind(this);
    this.updateCredentialsButtonState =
      this.updateCredentialsButtonState.bind(this);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;

    await this.getAccountActiveCommunication();
    await this.getListing();
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  getListing = async () => {
    const { accountId, language, filters, windowLocation } = this.state;
    const { documentsTab } = this.props;

    this.setState((prevState) => ({
      globalTableKey: prevState.globalTableKey + 1,
    }));

    const response = await sequencesRequest.fetchSequencesData(
      { accountId, language },
      { documentsTab, filters, windowLocation }
    );

    this.setDocumentsAndTotals(response);
  };

  /**
   * Fetch data about an account's at communication configuration.
   * @function
   */
  getAccountActiveCommunication = async () => {
    const accountId = this.state.accountId;

    const {
      fiscal_id,
      at_user,
      legacy_credentials_present,
      account_credentials_present,
    } = await AccountsRequest.checkAccountActiveCommunication(
      accountId,
      AT_TYPE,
      AT_CONTEXT_SEQUENCES
    );

    this.setState({
      credentialsInformation: {
        accountId: this.state.accountId,
        fiscalId: fiscal_id,
        atUser: at_user,
        legacyCredentialsPresent: legacy_credentials_present,
        accountCredentialsPresent: account_credentials_present,
      },
    });
  };

  updateCredentialsButtonState = (username) => {
    this.setState((prevState) => ({
      credentialsInformation: {
        ...prevState.credentialsInformation,
        atUser: username,
        accountCredentialsPresent: true,
      },
    }));
  };

  /**
   * Prepare filters for API request and reset page filter
   * Search by text
   * @function
   * @param {string} searchTerm - Page number to be requested.
   */
  async getSequencesByTextInput(searchTerm) {
    const { accountId, language, filters, windowLocation } = this.state;
    const { documentsTab } = this.props;
    const queryStringParams = { text: searchTerm, page: 1 };

    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          text: searchTerm,
          page: 1,
        },
        globalTableKey: (prevState.globalTableKey += 1),
      }),
      async () => {
        stateManager.updateQueryString(queryStringParams, this.state);

        const response = await sequencesRequest.fetchSequencesData(
          { accountId, language },
          { documentsTab, filters, windowLocation }
        );

        this.setDocumentsAndTotals(response);
      }
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by sequences status
   * @function
   * @param {object} status - JSON with status filters applied.
   */
  async getDocumentsByStatus(status) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      status: status,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          status: status,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchStatusDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by sequences type
   * @function
   * @param {object} types - JSON with type filters applied.
   */
  async getDocumentsByType(types) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      types: types,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          type: types,
          page: 1,
        },
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchTypeDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and change items per page
   * @function
   * @param {string} itemsToShow - items per page requested.
   */
  async setItemsPerPage(itemsToShow) {
    const extraInformation = {
      itemsPerPage: itemsToShow,
    };

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          itemsPerPage: itemsToShow,
          page: 1,
        },
        globalTableKey: prevState.globalTableKey + 1,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    await documentsPerPage(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * sort by document property and order
   * @function
   * @param {string} sort - document property.
   * @param {string} sortOrder - sort order applied.
   */
  async sortDocumentsByPropertyAndOrder(sort, sortOrder) {
    const extraInformation = {
      sortArgument: sort,
      sortOrderArgument: sortOrder,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sort: sort,
          sortOrder: sortOrder,
          page: 1,
        },
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await sortDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Check page number boundaries and prepare filters for API request
   * Search specific page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  async getDocumentsPage(nextPage) {
    const stateCopy = this.state;
    stateCopy.filters.page = nextPage;
    stateCopy.globalTableKey += 1;
    stateCopy.isLoading = true;
    this.setState({ ...this.state, stateCopy });

    queryStringManager.updateQueryStringParam(
      'page',
      nextPage,
      this.state.windowLocation
    );

    const { accountId, language, filters, windowLocation } = this.state;
    const { documentsTab } = this.props;

    const response = await sequencesRequest.fetchSequencesData(
      { accountId, language },
      { documentsTab, filters, windowLocation }
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Sets the app state taking into account the API response.
   * @function
   * @param {object} response - JSON with search result (documents & summary).
   */
  setDocumentsAndTotals(response) {
    if (this._isMounted) {
      this.setState({
        sequences: response.sequences,
        numberOfPages: response.totals.pages,
        isLoading: false,
        isLoadingFirstRequest: false,
      });
    }
  }

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  resetAllFilters() {
    const queryStringWithPage = queryStringManager.buildQueryStringWithPage(
      this.state.filters.page
    );
    queryStringManager.clearQueryString(
      this.state.windowLocation,
      queryStringWithPage
    );
    this.clearListingData(queryStringWithPage);
  }

  /**
   * Sets the listing data back to default.
   * @function
   */
  async clearListingData(queryStringWithPage) {
    await this.fetchDefaultListingData();
    const newGlobalResetKey = this.state.globalResetKey + 1;
    let queryString = '';

    if (typeof queryStringWithPage === 'string') {
      queryString += queryStringWithPage;
    }

    const newFilters = parseAndSetSearchFilters({
      itemsPerPage: this.props.itemsPerPage,
      windowLocation: { search: queryString },
      documentsTab: this.props.documentsTab,
    });

    this.setState({
      filters: newFilters,
      globalResetKey: newGlobalResetKey,
    });
  }

  /**
   * Gets the default documents and summary.
   * @function
   */
  async fetchDefaultListingData() {
    this.initialState.windowLocation.search = '';
    this.initialState.filters.itemsPerPage = this.props.itemsPerPage;

    // We don't need account settings again
    const documentsResponse = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.initialState.filters,
      this.initialState.windowLocation
    );

    this.setDocumentsAndTotals(documentsResponse);
  }

  /**
   * Updates Sequences listing after registration process
   *
   * @function
   *
   * @param {object} sequence - JSON object with the sequence whose state is being updated
   * @param {string} sequenceStatus - new Sequence status (active/inactive)
   * @param {string} sequenceRegisterStatus - new Sequence registration status (registered, parcial or not registered)
   */
  updateListing = (sequence, sequenceStatus, sequenceRegisterStatus) => {
    const sequenceIndex = this.state.sequences.indexOf(sequence);
    sequence.status = sequenceStatus;
    sequence.register_status = sequenceRegisterStatus;

    this.setState(({ sequences }) => {
      sequences[sequenceIndex] = sequence;
      return sequences;
    });
  };

  /**
   * Opens the mobile side menu.
   * @param {string} sideContent - the side content to display: options and information.
   * @function
   */
  openMobileMenu(sideContent) {
    this.setState({ showMobileMenu: true, mobileSideContent: sideContent });
  }

  /**
   * Closes the mobile side menu.
   * @function
   */
  closeMobileMenu() {
    helpScoutBeaconHelper.changeVisibility(false);
    this.setState({ showMobileMenu: false, mobileSideContent: '' });
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/rendering-elements.html
   * @function
   * @returns {object} React fragment. Read: https://reactjs.org/docs/fragments.html
   */
  render() {
    const {
      filters,
      showMobileMenu,
      mobileSideContent,
      globalResetKey,
      globalTableKey,
      isLoadingFirstRequest,
      isLoading,
      sequences,
      numberOfPages,
      userRole,
    } = this.state;

    return (
      <div id='main-content' className='container --sequences'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
            <div className='container'>
              <div className='row content-block title-block'>
                <div className='text-header h2'>
                  <FormattedMessage id='sequencesATCUD' />
                </div>
              </div>
              <div className='row content-block advanced-search-block'>
                <SearchBox
                  key={globalResetKey}
                  filters={filters}
                  setItemsPerPage={this.setItemsPerPage}
                  searchByPlugins={() => {}}
                  sortDocumentsByPropertyAndOrder={
                    this.sortDocumentsByPropertyAndOrder
                  }
                  sortOptions={sequencesSortOptions}
                  resetAllFilters={this.resetAllFilters}
                  openMobileMenu={this.openMobileMenu}
                  mobileSideContent={mobileSideContent}
                  closeMobileMenu={this.closeMobileMenu}
                  getSequencesByTextInput={this.getSequencesByTextInput}
                  getDocumentsByType={this.getDocumentsByType}
                  getDocumentsByStatus={this.getDocumentsByStatus}
                />
              </div>
              <TableSimple
                globalResetKey={globalResetKey}
                globalTableKey={globalTableKey}
                loadingFirstRequest={isLoadingFirstRequest}
                loading={isLoading}
                documents={sequences}
                filters={filters}
                numberOfPages={numberOfPages}
                getDocumentsPage={this.getDocumentsPage}
                getListing={this.getListing}
                updateListing={this.updateListing}
                documentsTab={this.props.documentsTab}
                openMobileMenu={this.openMobileMenu}
                mobileSideContent={mobileSideContent}
                closeMobileMenu={this.closeMobileMenu}
                sortOptions={sequencesSortOptions}
                accountId={this.props.accountId}
                credentialsInformation={this.state.credentialsInformation}
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <RightMenu
              mobileSideContent={mobileSideContent}
              closeMobileMenu={this.closeMobileMenu}
              documentsTab={this.props.documentsTab}
              getListing={this.getListing}
              credentialsInformation={this.state.credentialsInformation}
              updateCredentialsButtonState={this.updateCredentialsButtonState}
              userRole={userRole}
            />
          </div>
          <MobileMenu
            showMobileMenu={showMobileMenu}
            closeMobileMenu={this.closeMobileMenu}
            sideContent={mobileSideContent}
          />
          {/* Temporary button for legacy header to open create new document: */}
          <div
            className='legacy-header-button no-opacity'
            onClick={this.openMobileMenu.bind(this, 'mobileButtons')}
          />
        </div>
      </div>
    );
  }
}

import RequestAdapter from '../adapters/RequestAdapter';
import { V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Fetch a list of tax
 *
 * @function
 * @returns {object} JSON response
 */
const getTaxList = () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SETTINGS.TAX_LIST}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

export const SettingService = {
  getTaxList,
};

import {
  AT_ACTIVE_COMMUNICATION_PATH,
  FETCH_ACCOUNTS_PATH,
} from '../../../../../constants';
import { fetchDataRaw } from '../../../invoices/util/api/request';
import { RequestHelper } from './RequestHelper';

/**
 * Class responsible for handling generic requests related to Accounts.
 * If needed, this class may extend from /accounts/Request
 */
export class Request {
  static getAccountPath = (accountId) => `${FETCH_ACCOUNTS_PATH}/${accountId}`;

  static getAccountActiveCommunicationPath = (accountId, queryString) =>
    `${this.getAccountPath(
      accountId
    )}/${AT_ACTIVE_COMMUNICATION_PATH}${queryString}`;

  /**
   * Requests the BE to check if an account has the AT communication configured.
   *
   * @function
   *
   * @param {number} accountId - ID of the user's account
   * @param {string} type - Type of credentials (AT | AMA)
   * @param {string} context - Type of data to use the credentials (Invoices | Guides | Sequences)
   *
   * @returns {object} JSON response
   */
  static checkAccountActiveCommunication = async (accountId, type, context) => {
    const queryString =
      RequestHelper.buildQueryStringForCheckingAccountActiveCommunication(
        type,
        context
      );

    const path = this.getAccountActiveCommunicationPath(accountId, queryString);

    const result = await fetchDataRaw(path, 'accounts');

    return result.data;
  };
}

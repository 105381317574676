import React, { useContext, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { errorKeys } from '../../../../constants/documentCustomization';
import { AppContext } from '../../../../contexts/AppContext';
import { ContactService } from '../../../../services/ContactService';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import RightMenu from '../../../organisms/containers/contacts/RightMenu';
import SaveButtonBar from '../../../organisms/containers/customize-document/SaveButtonBar';
import { StateHelper } from '../../helper/state';
import SuccessModal from '../../feedback/modal/SuccessModal';
import { buildOptionsFromCollection } from '../../util/api/adapterHelper';
import { Helper } from './helper';
import BillingSection from './sections/BillingSection';
import DetailsSection from './sections/DetailsSection';
import PreferencesSection from './sections/PreferencesSection';

const EditContactPageLayout = (props) => {
  const { appState } = useContext(AppContext);
  const history = useHistory();
  const [fields, setFields] = useState({});
  const urlParams = useParams();

  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSendWithSuccess, setIsSendWithSuccess] = useState(true);
  const [requestErrors, setRequestErrors] = useState('');

  const isAnExternalPage = () => {
    return props.documentsTab === 'EditExternalClient';
  };

  const isCreateContactPage = () => {
    return props.documentsTab === 'CreateContact';
  };

  const onCancelClick = () => {
    history.goBack();
  };

  const showFeedback = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSendWithSuccess(false);
    }, 500);
  };

  /**
   * Call service to create or update contact
   *
   * @async
   * @returns {void}
   */
  const updateContactInformation = async () => {
    if (isRequiredFieldsFilled()) {
      const formData = Helper.formatRequestPayload(fields, countries);

      const createOrUpdate = async () => {
        return isCreateContactPage()
          ? await ContactService.create(formData)
          : await ContactService.updateById(urlParams.client_id, formData);
      };

      const response = await createOrUpdate();

      if (response.error) {
        setRequestErrors(response.error);
        return;
      }

      showFeedback();
    }
  };

  const isRequiredFieldsFilled = () => {
    return fields.name && fields.name !== '';
  };

  const onChangeField = (key, value) => {
    setFields(Helper.updateObject(fields, { key, value }));
  };

  useMemo(async () => {
    setPhoneCountryCodes(
      await StateHelper.fetchPhoneCountries(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )
    );

    setCountries(
      await StateHelper.fetchCountries(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )
    );

    if (!isCreateContactPage()) {
      const response = await ContactService.getById(urlParams.client_id);
      if (response.error) {
        setRequestErrors(response.error);
        return;
      }
      setFields(response.client);
    }

    const currenciesResponse =
      (await StateHelper.fetchCurrencies(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )) || [];
    setCurrencies(buildOptionsFromCollection('name', 'id', currenciesResponse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.accountId, urlParams.client_id]);

  const successModalMessage = isCreateContactPage()
    ? 'successMessageCreateModal'
    : 'successMessageEditModal';

  return (
    <div id='main-content' className='container --contacts'>
      <div className='row justify-content-center'>
        <div className='col-lg-9'>
          <div className='block-grid'>
            <div className='row grid-block two-cols-container'></div>
            <div className='row grid-block form-container'>
              {/* Header */}
              <div className='col-12 grid-block page-header-block'>
                <div className='text-header h2 text-align-left'>
                  {
                    props.intl.messages[
                      Helper.getTitleOfHeader(props.documentsTab)
                    ]
                  }
                </div>
                {isAnExternalPage() && (
                  <Paragraph>
                    {props.intl.messages['fiscalInformation']}
                  </Paragraph>
                )}

                {requestErrors.length > 0 && (
                  <RequestHandelingAlert error={requestErrors} />
                )}
              </div>

              <div className='col-12'>
                <DetailsSection
                  fields={fields}
                  phoneCountryCodes={phoneCountryCodes}
                  countries={countries}
                  onChangeField={onChangeField}
                  isExternalPage={isAnExternalPage()}
                />
              </div>

              {!isAnExternalPage() && (
                <>
                  <div className='col-12'>
                    <PreferencesSection
                      fields={fields}
                      phoneCountryCodes={phoneCountryCodes}
                      onChangeField={onChangeField}
                    />
                  </div>

                  <div className='col-12 mt-3'>
                    <Paragraph className='text-header h4 mb-1'>
                      {props.intl.messages['billingPreferences']}
                    </Paragraph>
                    <Span className='color-gray-400'>
                      {props.intl.messages['billingPreferencesSubtitle']}
                    </Span>
                    <div className='sep-light mt-2' />
                  </div>

                  <div className='col-12'>
                    <BillingSection
                      fields={fields}
                      currencies={currencies}
                      onChangeField={onChangeField}
                    />
                  </div>
                </>
              )}
              {/* Actions */}
              <SaveButtonBar
                label={props.intl.messages['saveContact']}
                onClick={updateContactInformation}
                onCancelClick={isAnExternalPage() ? false : onCancelClick}
                shouldRenderAlert={isSubmitted}
                success={isSubmitted}
                requestErrors={requestErrors}
                inputErrors={isRequiredFieldsFilled() ? [] : ['disabled']}
              />

              {!isAnExternalPage() && isSubmitted && (
                <SuccessModal
                  isLoading={isSendWithSuccess}
                  messageKey={successModalMessage}
                  onClose={() => history.goBack()}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-3 mt-5'>
          {!isAnExternalPage() && (
            <RightMenu
              isEditMode
              openMobileMenu={() => {}}
              closeMobileMenu={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EditContactPageLayout);

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Paragraph = (props) => (
  <p className={props.className}>
    {props.intl.messages[props.children] || props.children}
  </p>
);

Paragraph.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Paragraph);

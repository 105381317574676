import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object (options).
 */
const Select = props => (
  <select value={props.value} name='...' className={`select ${props.className}`} onChange={props.onChange} 
  disabled={props.disabled}>
    {props.options.map(elem => {
      return (
        <option key={`${elem}`} value={`${elem}`}>
          {props.intl.messages[elem] || elem}
        </option>
      );
    })}
  </select>
);

Select.propTypes = {
  options: PropTypes.array.isRequired,
  intl: PropTypes.object
};

export default injectIntl(Select);

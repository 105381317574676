import { injectIntl } from 'react-intl';
import React from 'react';
import Button from '../../../atoms/buttons/Button';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Icon from '../../../atoms/icons/Icon';
import { errorKeys } from '../../../../constants/documentCustomization';

const SaveButtonBar = (props) => {
  const hasFetchError = props.requestErrors === errorKeys.fetchError;

  return (
    <div className='fixed-bottom-bar' id='bottom-bar'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-9 --flex'>
            {props.shouldRenderAlert && (
              <>
                {props.requestErrors && (
                  <div
                    className={`notification-message ${
                      props.requestErrors ? 'd-flex' : 'd-none'
                    }`}
                    id='request-error-alert'
                  >
                    <Icon className='icon fas fa-times color-error' />
                    <Paragraph className='notitification-text'>
                      {props.requestErrors}
                    </Paragraph>
                  </div>
                )}
                {props.success && (
                  <div
                    className={`notification-message ${
                      props.success ? 'd-flex' : 'd-none'
                    }`}
                  >
                    <Icon className='icon fas fa-check color-success' />
                    <Paragraph className='notitification-text'>
                      {'dataSavedSuccess'}
                    </Paragraph>
                  </div>
                )}
              </>
            )}
            <div className='col-md-2 col-xs-5'>
              <Button
                id={'save_button'}
                className='button button-primary'
                onClick={props.onClick}
                label={props.label || 'save'}
                disabled={props.inputErrors.length > 0 || hasFetchError}
              />
            </div>

            {props.onCancelClick && (
              <div className='col-md-2 col-xs-5 ml-2'>
                <Button
                  id={'cancel_button'}
                  className='button button-secondary ml-4'
                  onClick={props.onCancelClick}
                  label={'cancel'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SaveButtonBar);

import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from '../../../atoms/buttons/Button';
import Anchor from '../../../atoms/anchors/Anchor';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import NewSequenceModalRegistration from '../../../organisms/modals/sequences/NewSequenceModalRegistration';
import AtConfigurationModal from '../../../organisms/modals/sequences/AtConfigurationModal';
import AlertWithButton from '../../../molecules/alerts/AlertWithButton';
import { isAdministrator } from "../../../../constants/user_roles";
import { buildPath } from '../util/pathHelper';
import { KNOWLEDGE_BASE_ATCUD_LINKS, KNOWLEDGE_BASE_LABS_LINKS } from '../../../../constants/index.js';

const RightMenu = (props) => {
  const atConfigurationInitialState =
    window.location.href === buildPath('v3/sequences#configurationmodal');

  const accountCredentialsPresent =
    props.credentialsInformation?.accountCredentialsPresent;

  const [isModalNewSequenceVisible, setIsModalNewSequenceVisible] = useState(
    false
  );
  const [
    isModalAtConfigurationVisible,
    setIsModalAtConfigurationVisible,
  ] = useState(atConfigurationInitialState);

  const userIsAdmin = isAdministrator(props.userRole);
  
  /**
   * Creates a child component for each help information.
   * @function
   * @param {object} helpInfo - JSON object with each help information.
   * @returns {object} array with all child components.
   */
  const addHelpInfo = () => {
    const helpInfo = ['whatIsATCUDHelp', 'whatIsSequenceHelp', 'whatIsDefaultSequenceHelp', 'deleteSequenceHelp'];

    return helpInfo.map((element, index) => (
      <FormattedMessage
        key={element}
        id={helpInfo[index]}
        values={{
          span: (chunks) => (
            <span className='d-block bold title'>{chunks}</span>
          ),
          p: (chunks) => <span className='d-block paragraph'>{chunks}</span>,
          a: (chunks) => (
            <Anchor
              href={
                element === 'whatIsSequenceHelp'
                  ? KNOWLEDGE_BASE_ATCUD_LINKS['atconfiguration']
                  : KNOWLEDGE_BASE_LABS_LINKS['atcud']
              }
              className='bold color-brand-primary'
              target='_blank'
            >
              {chunks}
            </Anchor>
          ),
        }}
      />
    ));
  };

  const summaryMobileDisplay = openMobileSideContent(
    'mobileSummary',
    props.mobileSideContent
  );

  const buttonsMobileDisplay = openMobileSideContent(
    'mobileButtons',
    props.mobileSideContent
  );

  if (summaryMobileDisplay === 'open' || buttonsMobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='container'>
      <div
        className={`buttons-block mobile-side-content ${buttonsMobileDisplay}`}
      >
        <div className='row content-block button-primary-block'>
          <Button
            className='button button-primary d-block'
            label='createNewSequence'
            onClick={() => setIsModalNewSequenceVisible((prevState) => !prevState)}
          />
        </div>
        <div
          className={`row content-block summary-table-block mobile-side-content ${summaryMobileDisplay}`}
        >

          <div className='content-block at-configuration-block'>
            <div className='text-header h4'>
              {props.intl.messages['atCommunication']}:
            </div>

            <AlertWithButton
              alertType={accountCredentialsPresent ? 'alert-active' : 'alert-warning'}
              buttonLabel='configuration'
              buttonDisabled={!userIsAdmin}
              onClick={() => setIsModalAtConfigurationVisible((prevState) => !prevState)}
              alertMessage={accountCredentialsPresent ? 'activeConnection' : 'inactiveConnection'}
              tooltipMessage={'atConfigurationTip'}
              tooltipType={'--notification'}
            />
          </div>

          <InformativeDropdown
            label='help'
            dropdownType='help-info-panel'
            iconClassName='icon fas fa-info-circle'
            isVisible={true}
          >
            {addHelpInfo()}
          </InformativeDropdown>
        </div>
      </div>
      {isModalNewSequenceVisible && (
        <NewSequenceModalRegistration
          changeModalVisibility={() =>
            setIsModalNewSequenceVisible((prevState) => !prevState)
          }
          isAtCommunicationActive={accountCredentialsPresent}
          documentsTab={props.documentsTab}
          accountId={props.credentialsInformation.accountId}
          getListing={props.getListing}
          isDemoAccount={props.isDemoAccount}
        />
      )}
      {isModalAtConfigurationVisible && (
        <AtConfigurationModal
          changeModalVisibility={() =>
            setIsModalAtConfigurationVisible((prevState) => !prevState)
          }
          credentialsInformation={props.credentialsInformation}
          updateCredentialsButtonState={props.updateCredentialsButtonState}
        />
      )}
    </div>
  );
};

export default injectIntl(RightMenu);

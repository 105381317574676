/**
 * Builds the options used to request the BE to set the default sequence
 * 
 * @function
 * 
 * @param {number} accountId - ID of the user's account
 * @param {string} serieName - sequence name that should be set as default
 * 
 * @returns {object} JSON object with the body and the method used on the request
 */
export const buildDefaultSequenceOptions = (accountId, serieName) => {
  const body = {
    account_id: accountId,
    serie_name: serieName,
  };

  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
  };

  return options;
};

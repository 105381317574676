import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RowNoSelection from './RowNoSelection';
import RowSequenceRegistration from './RowSequenceRegistration';
import RowContacts from './RowContacts';
import RowBalanceMovement from './RowBalanceMovement';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table body that renders all rows considering the props.
 */
const BodyNoSelection = (props) => {
  const [isAllLinesSelected, setIsAllLinesSelected] = useState(false);

  const identifiers = [];
  const buildRow = {
    Schedules: (item) => (
      <RowNoSelection
        document={item.document}
        index={item.index}
        key={item.index}
        accountSettings={props.accountSettings}
        documentsTab={props.documentsTab}
      />
    ),
    Contacts: (item) => (
      <RowContacts
        id={props.id}
        data-testid={`contact-row-${item.index}`}
        document={item.document}
        index={item.index}
        key={item.index}
        accountSettings={props.accountSettings}
        documentsTab={props.documentsTab}
      />
    ),
    Sequences: (item) => (
      <RowSequenceRegistration
        sequence={item.document}
        accountId={props.accountId}
        getListing={props.getListing}
        updateListing={props.updateListing}
        index={item.index}
        key={item.index}
        isLoading={item.document.state_change_in_progress}
        credentialsInformation={props.credentialsInformation}
      />
    ),
    ViewContact: (item) => (
      <RowBalanceMovement
        id={props.id}
        data-testid={`balance-movement-row-${item.index}`}
        document={item.document}
        index={item.index}
        key={item.index}
        accountSettings={props.accountSettings}
        documentsTab={props.documentsTab}
        setSelectedLines={props.setSelectedLines}
        selectedLines={props.selectedLines}
        setIsAllLinesSelected={setIsAllLinesSelected}
        isAllLinesSelected={isAllLinesSelected}
        identifiers={identifiers}
      />
    ),
  };

  const { documents } = props;

  return (
    <div className='list-of-cards'>
      {buildRow[props.documentsTab]
        ? documents.map((document, index) =>
            buildRow[props.documentsTab]({
              document,
              index,
            })
          )
        : ''}
    </div>
  );
};

BodyNoSelection.propTypes = {
  documents: PropTypes.array,
  accountSettings: PropTypes.object,
};

export default BodyNoSelection;

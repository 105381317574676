import { errorKeys } from '../../constants/documentCustomization';

/**
 * Enum HTTP Status
 * @enum {number}
 * @readonly
 */
const HTTP_STATUS = {
  NO_CONTENT: 204,
};
Object.freeze(HTTP_STATUS);

export class ErrorHandler {
  static handleRequest = async (call) => {
    try {
      const response = await call();

      const responseJson =
        HTTP_STATUS.NO_CONTENT !== response.status ? await response.json() : {};

      if (responseJson.errors) {
        return responseJson.errors[0];
      }
      return responseJson;
    } catch {
      return { error: errorKeys.fetchError };
    }
  };
}

/// <reference path="./typedefs.js" />
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import Button from '../../../atoms/buttons/Button';
import Icon from '../../../atoms/icons/Icon';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';
import ReactHtmlParser from 'react-html-parser';
import Image from '../../../atoms/images/Image';
import ConfirmationModal from '../../../organisms/modals/ConfirmationModal';
import { SignatureProviderService } from '../../../../services';

/**
 * Enum for authentication method
 * @enum {string}
 * @readonly
 */
const PROVIDER_GRANT_TYPE = {
  LINK: 'implicit',
  MODAL: 'client_credentials',
};

/**
 * Card to show an introduction and configure a provider
 *
 * @type {ProviderType} - provider object
 * @param {Intl} intl - translations
 * @param {Function} onConfigure - call configuration request
 * @returns {ReactComponentElement}
 */
const DigitalSignatureProviderCard = ({
  provider,
  intl,
  openModal,
  isActiveMode,
  reload,
}) => {
  const [isConsentAgreementChecked, setIsConsentAgreementChecked] =
    useState(false);
  const [isProviderDeleted, setIsProviderDeleted] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  /**
   * Delete a provider configuration
   */
  const handleDelete = async () => {
    setIsProviderDeleted(true);
    await SignatureProviderService.deleteProvider();
    reload();
    window.scrollTo(0, 0);

    setTimeout(() => {
      setIsProviderDeleted(false);
      setShowConfirmationModal(false);
    }, 1000);
  };

  /**
   * Open modal or redirect page by type
   */
  const handleConfiguration = () => {
    if (provider.grant_type === PROVIDER_GRANT_TYPE.LINK) {
      window.open(provider.url_auth, '_blank', 'noreferrer');
      return;
    }
    openModal(provider.id);
  };

  return (
    <>
      <div className='col-lg-12 col-md-11 --ds-provider'>
        <div className='row align-items-center'>
          <div className='col-lg-3 text-align-center'>
            <Image src={provider?.url_image} alt='AMA' />
          </div>

          <div className='col-lg-9 col-md-10 --ds-provider-content'>
            <div className='row'>
              <div className='col-6'>
                <div className='text-header h2'>{provider?.name}</div>
              </div>
              {!isActiveMode && (
                <div className='col-6 --ds-link'>
                  <Anchor href={provider?.url_guide} target='_blank'>
                    <Span className='text-paragraph bold'>
                      {intl.messages['guideToConfigureDS']}
                    </Span>
                  </Anchor>
                  <Icon className='icon fas fa-info-circle' />
                </div>
              )}
            </div>

            <div className='row mt-3'>
              <Paragraph className='text-paragraph --ds-description'>
                {ReactHtmlParser(provider?.description)}
              </Paragraph>
            </div>

            <div className='row --ds-actions'>
              {isActiveMode ? (
                <div className='col-12 text-align-right pr-4'>
                  {provider.expired && (
                    <Button
                      id='ds-renew-signature'
                      iconClassName='fa fa-sync-alt'
                      className='renew-button bold color-ix-white --medium'
                      label={intl.messages['renewSignature']}
                      onClick={async () => {
                        await SignatureProviderService.renewDigitalSignature();
                        handleConfiguration();
                      }}
                    />
                  )}

                  <Anchor
                    id='ds-delete-provider'
                    iconClassName='fa fa-trash'
                    className='color-red-200 --medium'
                    onClick={() => setShowConfirmationModal(true)}
                  >
                    &nbsp;{intl.messages['removeDigitalSignature']}
                  </Anchor>
                </div>
              ) : (
                <>
                  <div className='col-8'>
                    <CheckBoxLabel
                      id={`ds-agreement-${provider.id}`}
                      className='checkbox'
                      onChange={(e) =>
                        setIsConsentAgreementChecked(e.target.checked)
                      }
                    >
                      <Span className='text-paragraph bold --small'>
                        {intl.messages['permissionToConfigureDS']}
                      </Span>
                    </CheckBoxLabel>
                  </div>

                  <div className='col-4 text-align-right pr-4'>
                    <Button
                      id={`ds-configure-provider-${provider.id}`}
                      className='button button-primary --medium'
                      onClick={() => handleConfiguration()}
                      disabled={!isConsentAgreementChecked}
                    >
                      {intl.messages['configureDigitalSignature']}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          modalMessageId={'deleteProviderMessage'}
          confirmButtonId={'confirmAction'}
          onConfirmationFunction={handleDelete}
          changeModalVisibility={() => setShowConfirmationModal(false)}
          isLoading={isProviderDeleted}
        />
      )}
    </>
  );
};

export default injectIntl(DigitalSignatureProviderCard);

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import Image from '../../atoms/images/Image';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Alert = (props) => {
  return (
    <div id={props.id} className={`alert ${props.alertType}`}>
      {props.iconClassName && (
        <Icon className={`icon ${props.iconClassName}`} />
      )}
      {props.headerMessage && (
        <div className='header-icon'>
          <Icon className={`icon ${props.headerIconClassName}`} />
          {props.headerMessage}
        </div>
      )}
      {props.children}
      {props.imageSrc && (<Image src={props.imageSrc}/>)}
    </div>
  );
};

Alert.propTypes = {
  alertType: PropTypes.string,
  headerIconClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default Alert;

/** Clients Route */
const CLIENTS = {
  CREATE: 'api/v3/clients.json',
  LIST: 'api/v3/clients',
  DETAILS: (clientId) => `api/v3/clients/${clientId}.json`,

  INITIAL_BALANCE: (clientId) =>
    `api/v3/clients/${clientId}/initial_balance.json`,
  BALANCE: (clientId) => `api/v3/clients/${clientId}/balance.json`,
  REGULARIZE_BALANCE: (clientId) => `api/v3/clients/${clientId}/regularization`,
  TREASURY_MOVEMENT: (clientId) =>
    `api/v3/clients/${clientId}/treasury_movements.json`,

  CHECKING_ACCOUNT_EMAIL: (clientId) =>
    `api/v3/clients/${clientId}/send_open_account_link_mail.json`,
  BANK_ACCOUNT_INFO: (token) => `api/v3/billings/${token}/account_info`,
};
Object.freeze(CLIENTS);

/** Sequences Route */
const SEQUENCES = {
  REGISTERED_SERIES: 'api/v3/sequences/registered_series.json',
};
Object.freeze(SEQUENCES);

/** Notifications Route */
const NOTIFICATIONS = {
  TEMPLATES: (type) => `api/v3/notification_configurations/${type}/search.json`,
  BILLING_ALERTS: 'api/v3/billing_alerts',
};
Object.freeze(NOTIFICATIONS);

const SIGNATURE_PROVIDER = {
  DEFAULT: 'api/v3/digital_signatures/providers.json',
  BY_ACCOUNT: 'api/v3/digital_signatures/accounts/providers.json',
  CREDENTIALS: 'api/v3/digital_signatures/accounts/credentials.json',
  TOKEN: 'digital_signature/decode_hash',
  INFO: 'api/digital_signature/info.json',
  RENEW: 'digital_signature/renew_digital_signature.json',
};
Object.freeze(SIGNATURE_PROVIDER);
/** Plugins Route */
const PLUGINS = {
  DEFAULT: `api/v3/plugins`,
  BY_ACCOUNT: `api/v3/accounts/plugins`,
};
Object.freeze(PLUGINS);

/** Integrations Route */
const PLUGIN_INTEGRATIONS = {
  DEFAULT: 'api/v3/accounts/integrations',
  STATUS: (id) => `api/v3/accounts/integrations/${id}/toggle_status`,
};
Object.freeze(PLUGINS);

/** Application Settings Route */
const SETTINGS = {
  TAX_LIST: 'api/v3/taxes',
};
Object.freeze(PLUGINS);

/** Auths Route */
const AUTHENTICATION = {
  LOGIN: 'api/v3/auth/validate_login_and_password.json',
  RECOVER_PASSWORD: 'api/v3/users/recover_password.json',
  RESET_PASSWORD: 'api/v3/users/reset_password.json',
  SESSION: 'sessions',
  TWO_FACTOR_AUTHENTICATION: (userId) =>
    `api/v3/two_factors/${userId}/authenticate.json`,
};
Object.freeze(PLUGINS);

export const ENDPOINTS = {
  CLIENTS,
  SEQUENCES,
  NOTIFICATIONS,
  SIGNATURE_PROVIDER,
  PLUGINS,
  PLUGIN_INTEGRATIONS,

  SETTINGS,
  AUTHENTICATION,
};

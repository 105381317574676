import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../../atoms/icons/Icon';
import Button from '../../../atoms/buttons/Button';
import Label from '../../../atoms/labels/Label';
import Input from '../../../atoms/inputs/Input';
import Anchor from '../../../atoms/anchors/Anchor';
import Span from '../../../atoms/spans/Span';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';
import Alert from '../../../molecules/alerts/Alert';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import { getErrorMessage, getErrorMessageFromCode } from './util/errors';
import * as sequencesRequest from '../../../templates/util/api/sequences/request';
import { useEffect } from 'react';
import { LOADING_TIME, KNOWLEDGE_BASE_ATCUD_LINKS } from '../../../../constants';
import { isSerieNameValid } from './util/validations';
import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const NewSequenceModal = (props) => {
  const [useAsDefault, setUseAsDefault] = useState(true);
  const [error, setError] = useState('');
  const [atError, setAtError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [finishedWithSuccess, setFinishedWithSuccess] = useState(false);
  const [timer, setTimer] = useState(0);
  const [sequenceType, setSequenceType] = useState('normal');

  /**
   * Effect hook. Read: https://reactjs.org/docs/hooks-effect.html
   *
   * @function
   */
  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  /**
   * Handles the response from the backend and decided what to do,
   * in case of success or error.
   *
   * @param {Object} response - HTTP response.
   */
  const handleResponse = (response, createAndRegistry) => {
    if (response.success) {
      setFinishedWithSuccess(true);
    } else if (createAndRegistry && typeof response.data !== 'undefined') {
      setAtError(response.data.errors.message_code);
    } else {
      setError(response.errors[0].code);
    }

    props.getListing();
    setIsLoading(false);
  };

  /**
   * Requests the backend to create a sequence.
   *
   * @param {boolean} createAndRegistry - Indicate if the serie is to be registered or not.
   */
  const createSequence = async (createAndRegistry) => {
    setIsLoading(true);

    // Clear previous error messages
    setError('');
    setAtError('');

    await sequencesRequest.createSequence(
      inputValue,
      useAsDefault,
      props.accountId,
      props.documentsTab,
      createAndRegistry,
      (response) => setTimer(setTimeout(() => handleResponse(response, createAndRegistry), LOADING_TIME))
    );
  };

  /**
   * Function called upon the click of the Confirmation button
   * It will perform the desired action and exit the modal
   *
   * @function
   * @param {boolean} createAndRegistry - Indicate if the serie is to be registered or not.
   */
  const onConfirmClick = (createAndRegistry) => {
    const validSerieName = isSerieNameValid(inputValue);

    if (!validSerieName) {
      setError('invalid_serie');
      return;
    }

    createSequence(createAndRegistry);
  };

  /**
   * Function called upon the click of the Close button, It will trigger the close of the modal.
   *
   * @function
   */
  const onCloseClick = (props) => {
    props.changeModalVisibility();
  };

  /**
   * Considering the response renders the right error alert.
   * @function
   * @returns {object} returns Alert component.
   */
  const addErrorAlert = () => {
    if (error === '' && atError === '') 
      return null;

    let errorMessage = null;

    if (error !== '') {
      errorMessage = getErrorMessage(error);
      errorMessage = props.intl.messages[errorMessage];
    } else {
      errorMessage = getErrorMessageFromCode(atError);
      errorMessage = `${props.intl.messages['sequenceSavedButNotRegistered']} ${props.intl.messages[errorMessage]}`;
    }

    return (
      <Alert iconClassName='fas fa-exclamation-circle' alertType='--icon alert-error --small'>
        {errorMessage}
      </Alert>
    );
  };

  /**
   * Considering the response renders the right error alert.
   * @function
   * @returns {object} returns Alert component.
   */
  const onChangeInputValue = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className='modal-container d-block'>
      <div className='modal-content-container --small full-height-in-mobile'>
        {/* Close */}
        <div className='close-icon' onClick={() => onCloseClick(props)}>
          <Icon className='icon fas fa-times' />
        </div>

        {/* Modal content */}
        <div className='modal-content modal-scroll text-align-left'>
          {isLoading && (
            <div className='loading loading-tables'>
              <IxLoading />
            </div>
          )}

          {!finishedWithSuccess ? (
            <>
              <div className='text-header h3'>
                {props.intl.messages['createNewSequence']}
              </div>

              <Label className='text-label'>
                {props.intl.messages['sequenceName']}:
              </Label>

              <Input
                className='d-block'
                type='text'
                onChange={onChangeInputValue}
                value={inputValue}
              />
              <Paragraph className='text-paragraph --small input-info'>
                {'sequenceNameInfo'}
              </Paragraph>

              <Label className='text-label'>
                {props.intl.messages['sequenceType']}:
              </Label>

              <div className='radio-buttons-container d-flex-row'>
                <RadioButtonLabel
                  id='normal'
                  className='checkbox round'
                  checked={sequenceType === 'normal'}
                  onChange={() => setSequenceType('normal')}
                >
                  {props.intl.messages['normal']}
                </RadioButtonLabel>
                {/* <RadioButtonLabel
                  id='formation'
                  className='checkbox round'
                  checked={sequenceType == 'formation'}
                  onChange={() => setSequenceType('formation')}
                >
                  {props.intl.messages['formation']}
                </RadioButtonLabel> */}
              </div>

              <CheckBoxLabel
                id={'useAsDefault'}
                className='checkbox margin-double-gutter'
                checked={useAsDefault}
                onChange={() => {
                  setUseAsDefault((prevState) => !prevState);
                }}
              >
                {'useAsDefault'}
              </CheckBoxLabel>

              <Paragraph className='text-paragraph --small input-info'>
                <FormattedMessage
                  id={'sequenceInfo'}
                  values={{
                    a: (...chunks) => (
                      <Anchor
                        href={KNOWLEDGE_BASE_ATCUD_LINKS['atcud']}
                        className='underline'
                        target='blank'
                      >
                        {chunks}
                      </Anchor>
                    ),
                  }}
                />
              </Paragraph>

              {props.isAtCommunicationActive ? (
                <Alert
                  alertType='--icon alert-information --small'
                  iconClassName='fas fa-info-circle'
                >
                  {
                    <FormattedMessage
                      id={'registrationInfo'}
                      values={{
                        b: (...chunks) => (
                          <Span className='bold'>{chunks}</Span>
                        ),
                      }}
                    />
                  }
                  {props.isDemoAccount && (
                    <Span className='paragraph'>
                      <FormattedMessage
                        id={'registrationDemoAccountInfo'}
                        values={{
                          b: (...chunks) => (
                            <Span className='bold'>{chunks}</Span>
                          ),
                        }}
                      />
                    </Span>
                  )}
                </Alert>
              ) : (
                <Alert
                  alertType='alert-warning --icon --small'
                  iconClassName='fas fa-exclamation-triangle'
                >
                  {props.intl.messages['registrationAtCommunicationWarning']}
                </Alert>
              )}
              {addErrorAlert()}

              <div className='row buttons-container align-items-center justify-content-between'>
                {/* Buttons */}
                <div className='col col-lg-2 text-align-left'>
                  <Button
                    id='cancelAction'
                    className='button button-plain-text'
                    onClick={() => onCloseClick(props)}
                    label='cancelAction'
                  />
                </div>
                <div className='col text-align-right'>
                  <Button
                    id='registrationConfirmAction'
                    className='button button-primary'
                    onClick={() => onConfirmClick(true)}
                    label='saveAndRegistration'
                    disabled={!inputValue || !props.isAtCommunicationActive}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='text-header h2 text-align-center'>
                {props.intl.messages['sequenceCreationSuccess']}
              </div>
              <div className='text-align-center'>
                <Paragraph className='text-paragraph color-gray-base'>
                  {props.intl.messages['sequenceRegistrationStatusMessage']}
                </Paragraph>
                <Paragraph className='text-paragraph color-gray-base bold'>
                  {props.intl.messages['sequenceRegistrationFinishMessage']}
                </Paragraph>
              </div>
              <div className='buttons-container align-items-center text-align-center'>
                <Button
                  id='closeAction'
                  className='button button-primary'
                  onClick={() => onCloseClick(props)}
                  label='closeAction'
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

NewSequenceModal.propTypes = {
  showModal: PropTypes.bool,
  performedAction: PropTypes.string,
  modalMessageId: PropTypes.string,
  confirmButtonId: PropTypes.string,
  changeModalVisibility: PropTypes.func,
};

export default injectIntl(NewSequenceModal);

import React, { useState } from 'react';
import SearchForm from '../../../molecules/forms/SearchForm';
import { injectIntl } from 'react-intl';
import { openMobileSideContent } from '../util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';

const SearchBox = (props) => {
  const [currentTextSearch, setCurrentTextSearch] = useState(
    props.filters.text
  );
  const [previousTextSearch, setPreviousTextSearch] = useState(
    props.filters.text
  );
  const [textChanged, setTextChanged] = useState(false);

  /**
   * Submit form preventing page reload.
   * @function
   * @param {object} e - event.
   */
  const submitSearch = (e) => {
    e.preventDefault();

    // prevent request
    if (textChanged === false) {
      return;
    }

    setTextChanged(false);
    setPreviousTextSearch(currentTextSearch);

    props.getDocumentsByTextInput(currentTextSearch);
  };

  /**
   * Child input onChange event.
   * @function
   * @param {object} e - event.
   */
  const onChangeInputValue = (e) => {
    const newTextInputValue = e.target.value;

    setTextChanged(newTextInputValue !== previousTextSearch);
    setCurrentTextSearch(e.target.value);
  };

  const { filters } = props;

  const mobileDisplay = openMobileSideContent(
    'mobileFilters',
    props.mobileSideContent
  );

  if (mobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='advanced-search --mol'>
      <div className='search-bar-row margin-bottom'>
        <SearchForm
          id={props.id}
          searchTerm={filters.text}
          textChanged={textChanged}
          onChangeInputValue={onChangeInputValue}
          onSubmit={submitSearch}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
};

export default injectIntl(SearchBox);
